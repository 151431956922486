import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/Layout"
import TimeSince from "../components/TimeSince"
import { Flickr } from "../components/Links"

import Gallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

import "../styles/style.css"

export const query = graphql`
  query FlickrMostRecent25 {
    allFlickrPhoto(sort: { fields: datetaken, order: DESC }, limit: 24) {
      nodes {
        dateupload_date
        url_l
        description
        localImage {
          base
          childImageSharp {
            full: fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
            thumb: fluid(maxWidth: 480, maxHeight: 480, cropFocus: ATTENTION) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
const getFlickrPageUrl = base => {
  const photoId = base.split("_")[0]
  return `https://www.flickr.com/photos/iainjames9/${photoId}`
}

const extractImageDetails = node => {
  return {
    childImageSharp: node.localImage.childImageSharp,
    fluid: node.localImage.childImageSharp.fluid,
    hostedUrl: getFlickrPageUrl(node.localImage.base),
    uploadDatetime: node.dateupload_date,
    description: node.description,
  }
}

const getMostRecentUploadedDateTime = ({ imageDetails }) => {
  const { uploadDatetime: mostRecentUploadedDatetime } = imageDetails[0]
  return mostRecentUploadedDatetime
}

export default function Photos({ data }) {
  const imageDetails = data.allFlickrPhoto.nodes.map(extractImageDetails)

  const mostRecentUploadedDatetime = getMostRecentUploadedDateTime({ imageDetails })

  const galleryData = imageDetails.map(d => ({
    full: d.childImageSharp.full,
    thumb: d.childImageSharp.thumb,
    thumbAlt: d.description,
    key: d.childImageSharp.full.src,
  }))

  const lightboxOptions = {
    enableZoom: false,
  }

  return (
    <Layout>
      <main>
        <section className="page-callout">
          <h1>Photography</h1>
        </section>
        <section className="description">
          <p>
            My most recent photographs can be seen below (last updated{" "}
            <TimeSince datetime={mostRecentUploadedDatetime} />
            ).
          </p>
          <p>
            Check them out at a higher resolution on <Flickr>Flickr</Flickr>.
          </p>
        </section>

        <section className="gallery-container">
          <Gallery images={galleryData} lightboxOptions={lightboxOptions} />
        </section>
      </main>
    </Layout>
  )
}
